import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import {
  Audit,
  AuditCollection,
  AuditData,
} from "@planetadeleste/vue-mc-audits";

type VueClassMixin = VueClass<ModelMixin<Audit, AuditCollection>>;
const TypedModelMixin = ModelMixin as unknown as VueClassMixin;

interface AuditsMixin {
  obModel: Audit;
  obItem: AuditData;
  obCollection: AuditCollection;
}

export interface AuditItem {
  key: string;
  value: any;
  label: string;
}

@Component
class AuditsMixin extends Mixins(TypedModelMixin) {
  obCollection = new AuditCollection();
  obModelClass = Audit;
  sRoutePath = "/audits";
  hideActionsHeader = true;

  created() {
    this.onCreated();
  }
}

export default AuditsMixin;
