<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <form-observer hide-save-action hide-top-actions @cancel="cancel">
      <v-row v-if="obUser">
        <v-col cols="12" lg="4">
          <name-with-avatar
            :name="obUser.name"
            :path="obUser.avatar"
            :subtitle="obUser.email"
            :to="{ name: 'users.update', params: { id: obModel.user_id } }"
          />
        </v-col>
      </v-row>
      <audit-data-view :item="obModel" />
    </form-observer>
  </sheet-drawer>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import AuditsMixin from "@/modules/audits/mixins/AuditsMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import AuditDataView from "@/modules/audits/components/AuditDataView.vue";
import { UserData } from "@planetadeleste/vue-mc-shopaholic";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { Audit } from "@planetadeleste/vue-mc-audits";

@Component({
  components: {
    NameWithAvatar,
    AuditDataView,
    SheetDrawer,
  },
})
export default class AuditsForm extends Mixins(AuditsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;

  get obUser() {
    return this.obModel && this.obModel instanceof Audit
      ? (this.obModel.get("user") as UserData)
      : undefined;
  }
}
</script>
